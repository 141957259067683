<template>
  <div id="app-university-category" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form"  :model="adjust" label-width="130px" :rules="rules">
      <el-form-item label="选择院校" prop="universityId" >
        <el-select v-model="adjust.universityId" filterable placeholder="请选择" style="width:100%">
          <el-option
              v-for="university in universities"
              :key="university.id"
              :label="university.name"
              :value="university.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调剂年份" >
        <el-radio v-for="year in years" v-model="adjust.yearId" :label="year.id">
          {{year.name}}
        </el-radio>
      </el-form-item>
      <el-form-item label="调剂专业" >
        <el-checkbox  v-for="major in majors" v-model="adjust.majorIds" :label="major.id">
          {{major.name}}
        </el-checkbox >
      </el-form-item>
      <el-form-item label="学习方式">
        <el-checkbox  v-for="learnWay in learnWays" v-model="adjust.learnWayIds" :label="learnWay.id">
          {{learnWay.name}}
        </el-checkbox >
      </el-form-item>
      <el-form-item label="缺额" prop="lackNum">
        <el-input type="number" v-model="adjust.lackNum" placeholder = "请输入"></el-input>
      </el-form-item>
      <el-form-item label="上界最低分-总分" prop = "minScoreTotal">
        <el-input v-model="adjust.minScoreTotal" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上届最低分-法基" prop = "minScoreFaji">
        <el-input v-model="adjust.minScoreFaji" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上界最低分-法综" prop = "minScoreFazong">
        <el-input v-model="adjust.minScoreFazong" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上届最低分-外语" prop = "minScoreWaiyu">
        <el-input v-model="adjust.minScoreWaiyu" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上届最低分-政治" prop = "minScoreZhengzhi">
        <el-input v-model="adjust.minScoreZhengzhi" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input type="textarea" :rows="3" v-model="adjust.contact" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="3" v-model="adjust.remark" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { UrlEnum,TipEnum } from "../../../../../public/js/common-vue";
import { request } from "../../../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      adjust: {},
      universities:[],
      majors:[],
      years:[],
      learnWays:[],
      rules:{
        universityId:[{required: true, message:'请选择', trigger:'change'}],
        lackNum:[{required: true, message:'请输入', trigger:'blur'}],
        minScoreTotal:[{required: true, message:'请输入', trigger:'blur'}],
        minScoreFaji:[{required: true, message:'请输入', trigger:'blur'}],
        minScoreFazong:[{required: true, message:'请输入', trigger:'blur'}],
        minScoreWaiyu:[{required: true, message:'请输入', trigger:'blur'}],
        minScoreZhengzhi:[{required: true, message:'请输入', trigger:'blur'}]
      },
    }
  },
  methods: {
    initData: function () {
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITY_ADJUSTS + "/" + request('id'),
      }).then((res) => {
        this.adjust = res.data;
        console.log(this.adjust)
        this.getMajors();
        this.getYears();
        this.getUniversities();
        this.getLearnWays();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getUniversities:function(){
      this.$http({
        method: "get",
        url: UrlEnum.UNIVERSITIES+'?page=1&size=1000',
      }).then((res) => {
        this.universities = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getYears:function(){
      this.$http({
        method: "get",
        url: UrlEnum.YEARS+'?page=1&size=1000&projectId=2',
      }).then((res) => {
        this.years = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getMajors:function(){
      this.$http({
        method: "get",
        url: UrlEnum.MAJORS+'?page=1&size=100',
      }).then((res) => {
        this.majors = res.data.list;
        this.adjust.majorId = localStorage.getItem(LOCALSTORAGE_UNIVERSITY_MAJOR_KEY);
        if(this.adjust.majorId == null || this.adjust.majorId == 'undefined'){
          this.adjust.majorId = res.data.list[0].id;
        }
        this.adjust.majorId = parseInt(this.adjust.majorId);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getLearnWays:function(){
      this.$http({
        method: "get",
        url: UrlEnum.LEARN_WAYS+'?page=1&size=100',
      }).then((res) => {
        this.learnWays = res.data.list;
        console.log(this.learnWays);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    submit: function(formData){
      this.$refs[formData].validate((valid) => {if (valid) {
        this.update();
      }});
    },
    update:function (){
      this.loading=true;
      this.$http({
        method: "put",
        url: UrlEnum.UNIVERSITY_ADJUSTS + "/" + request('id'),
        data: this.adjust,
      })
      .then((res) => {
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        setTimeout(function () {
          parent.layui.table.reload('tableData'); //重载表格
          parent.layer.close(index); //再执行关闭
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    }
  },
  mounted() {
    this.initData();
  }
}
</script>

<style scoped>
@import "../../../../../static/css/add.css";
</style>
